import styled from 'styled-components'
import { Media } from '@dayancloud/common/theme'

export const Container = styled.div`
  background-color: #fff;
`

export const NavBox = styled.div`
  display: flex;
  align-items: center;
  width: 1200px;
  height: 80px;
  border: 2px solid #ffffff;
  background: linear-gradient(0deg, #fefdff 0%, #f4f4ff 100%);
  box-shadow: 0px 10px 20px 0px rgba(181, 181, 181, 0.15);
  border-radius: 4px;
  margin: -40px auto 30px;
  font-size: 18px;
  .title {
    color: #6b6cff;
    margin-left: 31px;
  }
  .line {
    font-size: 16px;
    color: #828995;
    opacity: 0.5;
    margin-right: 10px;
    margin-left: 8px;
  }
  .text {
    color: #2f323e;
  }
  ${Media.lessThan(Media.small)} {
    width: 345px;
    height: 58px;
    font-size: 15px;
    margin: -29px auto 17px;
    border: 1px solid #ffffff;
    box-shadow: 0px 5px 10px 0px rgba(181, 181, 181, 0.15);
    .title {
      margin-left: 21px;
    }
    .line {
      font-size: 14px;
      margin-right: 8px;
      margin-left: 7px;
    }
  }
`
export const ContentBox = styled.div`
  display: flex;
  width: 1200px;
  margin: 0 auto 100px;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px rgba(181, 181, 181, 0.2);
  position: relative;
  #cesiumContainer {
    width: 820px;
    height: 615px;
    background: #c9c9c9;
    border-radius: 4px 0px 0px 4px;
  }
  ${Media.lessThan(Media.small)} {
    display: flex;
    flex-direction: column;
    width: 345px;
    margin: 0 auto 30px;
    #cesiumContainer {
      width: 345px;
      height: 201px;
      border-radius: 3px 3px 0px 0px;
    }
  }
`
export const Sidebar = styled.div`
  width: 380px;
  height: 615px;
  border-radius: 4px;
  position: relative;
  .tag-box {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 114px;
    height: 38px;
    background: #f6f7fd;
    border-radius: 0px 4px 0px 16px;
    .icon {
      height: 20px;
      width: 20px;
      margin: 0 7px 0 17px;
    }
    .text {
      font-size: 14px;
      color: #6c6cff;
      line-height: 72px;
    }
  }
  .detail-box {
    margin: 53px 24px 24px;
    p {
      margin: 0;
      padding: 0;
    }
    .title {
      font-size: 20px;
      color: #2f323e;
      line-height: 28px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 13px;
      font-weight: 400;
    }
    .date {
      font-size: 14px;
      line-height: 1;
      color: #b1b5bc;
      margin-bottom: 30px;
    }
    .sub-title {
      font-size: 16px;
      line-height: 1;
      color: #828995;
      margin-bottom: 14px;
    }
    .desc {
      width: 332px;
      height: 402px;
      background: #f9f9fd;
      border-radius: 4px;
      padding: 20px 20px 38px;
      overflow-y: scroll;
      font-size: 14px;
      color: #828995;
      line-height: 22px;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: #e3e5ee;
        border-radius: 3px;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: 100%;
    .tag-box {
      width: 79px;
      height: 22px;
      justify-content: center;
      position: static;
      margin: 15px 0 0 15px;
      border-radius: 2px;
      .icon {
        height: 16px;
        width: 16px;
        margin: 0 2px 0 0;
      }
      .text {
        font-size: 12px;
        line-height: 24px;
      }
    }
    .detail-box {
      margin: 21px 16px 14px;
      .title {
        font-size: 17px;
        line-height: 24px;
      }
      .date {
        font-size: 12px;
        margin-bottom: 26px;
      }
      .sub-title {
        font-size: 14px;
        margin-bottom: 12px;
      }
      .desc {
        width: 100%;
        height: 100%;
        min-height: 342px;
        padding: 16px 15px 20px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
`
export const LoadingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 820px;
  height: 615px;
  background: #ffffff;
  border-radius: 4px 0px 0px 4px;
  .loading-icon {
    height: 28px;
    width: 28px;
    margin-bottom: 19px;
    animation: loading 1s infinite;
  }
  @keyframes loading {
    100% {
      transform: rotate(360deg);
    }
  }
  ${Media.lessThan(Media.small)} {
    border-radius: 3px 3px 0px 0px;
    width: 345px;
    height: 201px;
  }
`

import React from 'react'
import { SEO, Layout } from '@dayancloud/common/components'
import { Container, NavBox, ContentBox, Sidebar, LoadingBox } from './exampleDeatil.atom'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import ExampleBanner from '../molecules/index/example-banner'
import TryBanner from '../molecules/index/try-banner'
import loadingIcon from '../images/example/loading-icon.png'
class exampleDatail extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tilesetUrl: props.pageContext.tilesetUrl,
      isLoading: true,
    }
  }
  timer = 0
  componentDidMount() {
    this.showPreview()
  }
  componentWillUnmount() {
    this.timer && clearTimeout(this.timer)
  }
  // 获取资源路径
  showPreview() {
    this.timer && clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      try {
        // 创建视图实例
        const viewer = new Cesium.Viewer('cesiumContainer', {
          animation: false, //是否显示动画控件
          homeButton: false, //是否显示home键
          geocoder: false, //是否显示地名查找控件
          baseLayerPicker: false, //是否显示图层选择控件
          timeline: false, //是否显示时间线控件
          fullscreenButton: false, //是否全屏显示
          scene3DOnly: true, //如果设置为true，则所有几何图形以3D模式绘制以节约GPU资源
          infoBox: true, //是否显示点击要素之后显示的信息
          sceneModePicker: false, //是否显示投影方式控件  三维/二维
          navigationInstructionsInitiallyVisible: false,
          navigationHelpButton: false, //是否显示帮助信息控件
          selectionIndicator: false, //是否显示指示器组件
          sceneMode: Cesium.SceneMode.SCENE3D,
        })
        viewer._cesiumWidget._creditContainer.style.display = 'none' //	去除版权信息
        viewer.scene.globe.show = false
        const tileset = viewer.scene.primitives.add(
          new Cesium.Cesium3DTileset({
            url: this.state.tilesetUrl,
            maximumScreenSpaceError: 1,
            maximumNumberOfLoadedTiles: 1000,
          }),
        )
        viewer.zoomTo(tileset)
        tileset.readyPromise
          .then(model => {
            if (model.ready) {
              this.setState({
                isLoading: false,
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
      } catch (error) {
        console.log(error)
        if (error.message.includes('Cesium is not defined')) {
          this.showPreview()
        }
      }
    }, 1000)
  }

  render() {
    const {
      location,
      pageContext: {
        topFiveNews,
        title,
        date,
        content,
        categorie,
        seoTitle,
        seoDescription,
        seoKeywords,
      },
    } = this.props
    return (
      <Layout location={location} topFiveNews={topFiveNews}>
        <SEO
          title={`${seoTitle} - 大雁云`}
          description={seoDescription}
          keywords={seoKeywords && seoKeywords.join(', ')}
          shareImgSrc={`https:${this.props.pageContext.expImgSrc}`}
          ogUrl={`https://www.dayancloud.com${this.props.path}`}
          ogType='article'
        />
        <Helmet>
          <script src='https://dayancloud.oss-cn-shenzhen.aliyuncs.com/Cesium/Cesium.js'></script>
          <link
            rel='stylesheet'
            href='https://dayancloud.oss-cn-shenzhen.aliyuncs.com/Cesium/Widgets/widgets.css'
          ></link>
        </Helmet>
        <Container>
          <ExampleBanner />
          <NavBox>
            <Link to='/demos' className='title'>
              案例中心
            </Link>
            <span className='line'>/</span>
            <span className='text'>{title}</span>
          </NavBox>
          <ContentBox>
            <div
              id='cesiumContainer'
              style={{ visibility: this.state.isLoading ? 'hidden' : 'visible' }}
            ></div>
            <LoadingBox style={{ visibility: this.state.isLoading ? 'visible' : 'hidden' }}>
              {this.state.tilesetUrl && <img className='loading-icon' src={loadingIcon} alt='' />}
              <span>{this.state.tilesetUrl ? '加载中...' : '暂无数据'}</span>
            </LoadingBox>
            <Sidebar>
              <div className='tag-box'>
                <img className='icon' src={categorie?.cateImg?.fluid?.src} alt='' />
                <span className='text'>{categorie?.name}</span>
              </div>
              <div className='detail-box'>
                <h3 className='title'>{title}</h3>
                <p className='date'>发布于{date}</p>
                <p className='sub-title'>项目简介：</p>
                <ReactMarkdown
                  className='desc'
                  source={content}
                  escapeHtml={false}
                  linkTarget='_blank'
                />
              </div>
            </Sidebar>
          </ContentBox>
          <TryBanner />
        </Container>
      </Layout>
    )
  }
}

export default exampleDatail

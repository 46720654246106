import React from 'react'
import styled from 'styled-components'
import exampleBg from '../../images/example/exampleBanner.png'
import exampleBgMobile from '../../images/example/exampleBanner_mobile.png'
import { Button } from '@dayancloud/common/components'
import { onAuthEntry } from '../../../../common/service'
import { API } from '../../../../common/utils'
import { Media } from '@dayancloud/common/theme'

const Container = styled.div`
  height: 580px;
  background: url(${exampleBg});
  color: white;
  padding-top: 62px;
  background-size: 100% 100%;
  ${Media.lessThan(Media.small)} {
    height: 375px;
    background: url(${exampleBgMobile});
    padding-top: 50px;
    background-size: 100% 100%;
  }
`
const ContentContainer = styled.div`
  width: 1200px;
  margin: 116px auto;
  .title {
    width: 274px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    margin: 0;
  }
  .describe {
    font-size: 60px;
    line-height: 60px;
    font-family: 'Source Han Sans CN';
    font-weight: bold;
    letter-spacing: 3px;
    margin: 20px 0 46px;
  }
  > a {
    width: 160px;
    height: 44px;
    line-height: 44px;
    border-radius: 4px;
  }
  ${Media.lessThan(Media.small)} {
    width: 375px;
    margin: 78px auto;
    text-align: center;
    .title {
      width: 191px;
      margin: auto;
      height: 28px;
      font-size: 12px;
      border-radius: 2px;
      line-height: 28px;
    }
    .describe {
      font-size: 30px;
      letter-spacing: 0;
      line-height: 1;
      margin-bottom: 36px;
    }
    > a {
      width: 130px;
      height: 36px;
      line-height: 36px;
      font-size: 13.44px;
      border-radius: 2px;
    }
  }
`

class ExampleBanner extends React.PureComponent {
  render() {
    return (
      <Container>
        <ContentContainer>
          <h2 className='title'>大雁云 — 开启光速云重建时代</h2>
          <h1 className='describe'>精选实景三维重建案例</h1>
          <Button
            className='try-btn'
            as='a'
            rel='nofollow'
            onClick={() => onAuthEntry(`${API.auth.host}/sso`)}
          >
            免费试用
          </Button>
        </ContentContainer>
      </Container>
    )
  }
}

export default ExampleBanner
